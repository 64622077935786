.frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
}

.titleSection {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 1.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  margin-bottom: -1px;
  position: relative;
  width: fit-content;
}

.titleText1 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 1.875rem;
  font-weight: 400;
  letter-spacing: 0;
}

.alternateTitleText1 {
  display: none;
}

.titleText2 {
  font-family: "Poppins", Helvetica;
  font-weight: 900;
}

.nftSection {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.875rem;
  position: relative;
}

.nft {
  height: 17rem;
  position: relative;
  width: 14.25rem;
}

.overlap_group {
  -webkit-backdrop-filter: blur(26px) brightness(100%);
  backdrop-filter: blur(26px) brightness(100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #ffffff66;
  border-radius: 1.875rem;
  border-top-style: solid;
  border-top-width: 2px;
  height: 17rem;
  position: relative;
  width: 14.25rem;
}

.nftImage {
  height: 12.375rem;
  left: 0.75rem;
  object-fit: cover;
  position: absolute;
  top: 0.75rem;
  width: 12.75rem;
}

.frame .group {
  height: 1.5rem;
  left: 2.5rem;
  position: absolute;
  top: 14rem;
  width: 9.25rem;
}

.div_wrapper {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
}

.nftDescription {
  color: #ffffff;
  font-family: "Oxanium-Medium", Helvetica;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.claimButton {
  all: unset;
  align-items: center;
  background-color: #2537fe;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  padding: 0.625rem;
  position: relative;
  width: 11.25rem;
}

.claimButtonText {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.claimButton:hover {
  background-color: #0515c4;
  cursor: pointer;
}

@media screen and (min-width:320px) and (max-width:768px) {

  .titleSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem
  }

  .titleText1 {
    display: none;
  }

  .alternateTitleText1 {
    display: contents;
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
  }

  .titleText2 {
    font-family: "Poppins", Helvetica;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 900;
  }

}