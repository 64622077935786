.fullScreenModalContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.blur {
    backdrop-filter: blur(8px);
}

.modal {
    width: 378px;
    height: 339px;
    flex-shrink: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* Stay in place */
    z-index: 1;
    border: 1px solid rgba(214, 217, 255, 0.21);
    border-radius: 20px;
    background: rgba(1, 2, 22, 0.95);
}

.modalContent {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    padding: 24px 24px 20px 24px;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: calc(100% - 48px);
}

.modalHeader {
    display: inline-block;
    align-items: flex-start;
    width: 100%;
}

.modalHeaderLeft {
    display: flex;
    align-items: center;
    gap: 9px;
    width: 156px;
    float: left;
}

.modalHeaderRight {
    float: right;
}

.modalHeaderTitle {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: capitalize;
}

.arrowBackwardButton {
    width: 20px;
    height: 20px;
    background-image: url('../../assets/icons/arrow_backward.svg');
}

.arrowBackwardButton:hover {
    cursor: pointer;
}

.closeButton {
    width: 24px;
    height: 22px;
    background-image: url('../../assets/icons/close.svg');
}

.closeButton:hover {
    cursor: pointer;
}

.modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
}

.qrCodeSection {
    display: flex;
    align-items: center;
    gap: 1.375rem;
    width: 100%;
}

.qrCode {
    padding: 1rem;
    width: 7.75rem;
    height: 7.75rem;
    background-color: white;
    border-radius: 12px;
}

.modalBodyText {
    width: 149px;
    color: rgba(255, 255, 255, 0.70);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
}

.walletAddressSection {
    display: inline-block;
    width: calc(100% - 2rem);
    height: 21px;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    border: 1px solid #2E2F47;
}

.walletAddress {
    color: #FAFAFA;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    float: left;
    text-transform: uppercase;
}

.copyButton {
    float: right;
    width: 20px;
    height: 22px;
}

.copyButton:hover {
    cursor: pointer;
}

@media screen and (min-width:320px) and (max-width:768px) {
    .modal {
        width: 94%
    }

    .modalContent {
        gap: 2rem;
        padding: 1.5rem 0.75rem 1.25rem 0.75rem;
        width: calc(100% - 1.5rem)
    }
}