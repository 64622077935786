.header {
  display: inline-block;
  width: 100%;
}

.logoPlaceholder {
  height: 3rem;
  width: 12rem;
  float: left;
}

.logoPlaceholder .originalLogo {
  height: inherit;
  width: inherit;
}

.profileSection {
  display: flex;
  width: 15rem;
  flex-direction: column;
  align-items: center;
  float: right;
  height: 3rem;
  justify-content: center;
  border-radius: 0.75rem;
  border: 0.063rem solid rgba(214, 217, 255, 0.21);
  background: rgba(1, 2, 22, 0.95);
}

.profileContent {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.accountAddressSection {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.securityLogo {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.accountAddress {
  color: rgba(255, 255, 255, 0.80);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.063rem;
  text-transform: uppercase;
}

.accountAddress:hover {
  cursor: pointer;
}

.copyIcon {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.copyIcon:hover {
  cursor: pointer;
}

@media screen and (min-width:320px) and (max-width:768px) {
  .header {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    gap: 1.25rem;
  }
}