.body {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 3rem;
    height: 34rem;
}

.nftSection {
    width: 50rem;
    height: 34rem;
}

.frame {
    display: inline-flex;
    padding: 2rem 4.25rem 6.25rem 4.25rem;
    width: calc(100% - 8.5rem);
    height: calc(100% - 8.25rem);
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    border-radius: 1.25rem;
    border: 0.063rem solid rgba(214, 217, 255, 0.21);
    background: rgba(1, 2, 22, 0.50);
}

.nftTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.titleContent {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    width: inherit;
}

.title1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.title2 {
    color: rgba(255, 255, 255, 0.79);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.nftImageAndBadge {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.nftImageSection {
    width: 14.25rem;
    height: 18.625rem;
    padding-top: 0.875rem;
    border-radius: 1.875rem;
    border-top: 2px solid rgba(255, 255, 255, 0.40);
    border-bottom: 2px solid rgba(255, 255, 255, 0.40);
    background: linear-gradient(161deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 101.7%);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.875rem;
}

.nftImage {
    width: 12.75rem;
    height: 12.375rem;
    flex-shrink: 0;
    border-radius: 1.875rem;
    border-bottom: 2px solid #000C8C;
    background: url('../../assets/images/nft.png');
    backdrop-filter: blur(8px);
}

.nftName {
    width: 9.25rem;
    height: 1.5rem;
    flex-shrink: 0;
    color: #FFF;
    font-family: Oxanium;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.nftBadgeSection {
    display: flex;
    height: 0.875rem;
    padding: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    border-radius: 0.5rem;
    background: #990872;
}

.nftBadgeContent {
    display: flex;
    align-items: center;
    gap: 0.125rem;
}

.badgeIcon {
    width: 0.875rem;
    height: 0.875rem;
}

.nftBadgeText {
    display: flex;
    width: 4.75rem;
    height: 1rem;
    flex-direction: column;
    justify-content: flex-end;
    color: #FFF;
    font-family: Oxanium;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.buttonSection {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.disabledButton {
    pointer-events: none;
    opacity: 0.4;
}

.transferButton {
    width: 11.25rem;
    padding: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid #2537FE;
    background: #2537FE;
}

.transferButton:hover {
    cursor: pointer;
    background-color: #0515c4;
}

.transferButtonText {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.activitySection {
    margin-left: 2.625rem;
    width: 28rem;
    height: calc(100% - 4.5rem);
    flex-shrink: 0;
    border-radius: 1.25rem;
    border: 1px solid rgba(214, 217, 255, 0.21);
    background: rgba(1, 2, 22, 0.50);
    padding: 2.125rem 1.5rem 2.125rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
}

.activityTitle {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    height: 2.125rem;
}

.activityTable {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0.5rem 8rem;
    flex-wrap: wrap;
}

.activityTableRows {
    width: 100%
}

.headingSection {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0.5rem 8rem;
    flex-wrap: wrap;
    width: 100%;
}

.tableHeading {
    color: rgba(255, 255, 255, 0.40);
    text-align: center;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.activityData {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    height: 1.625rem;
    border-radius: 0.75rem;
    border: 1px solid #282A41;
    background: #0D0F22;
    padding: 0.75rem 1rem 0.75rem 0.75rem;
    margin-bottom: 1rem;
}

.activityDetails {
    display: flex;
    width: 25.375rem;
    height: 1.875rem;
    align-items: center;
    gap: 2rem;
    flex-shrink: 0;
}

.activityFrom {
    display: flex;
    width: 7.25rem;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
}

.profileIcon {
    width: 1.75rem;
    height: 1.75rem;
    flex-shrink: 0;
    border-radius: 1.75rem;
    /* background: url('../../assets/icons/bzl_logo.svg') rgba(1, 2, 22, 0.95) 50% / cover no-repeat; */
}

.username {
    color: #FFF;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.activityStatusIcon {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}

.activityStatusText {
    width: 4.5rem;
    flex-shrink: 0;
    color: rgba(255, 255, 255, 0.70);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
}

.activityTo {
    display: flex;
    width: 7.25rem;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
}

.activityType {
    display: flex;
    width: 7.375rem;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
}

.arrowForwardButtonSection {
    height: 1.625rem;
    display: flex;
    align-items: center;
}

.arrowForward {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
}

.arrowForward:hover {
    cursor: pointer;
}

.activityTableForMobileScreen {
    color: #FFF;
    display: none;
}

.activityContainer {
    border-radius: 12px;
    border: 1px solid #282A41;
    background: #0D0F22;
    width: 100%;
    height: 7rem;
    flex-shrink: 0;
    margin-top: 0.5rem;
}

.claimedStatus {
    background: #219653;
}

.transferredStatus {
    background: #E08B0B;
}

.activityStatusSection {
    border-radius: 12px 12px 0px 0px;
    border: 1px solid #282A41;
    height: 2.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    width: 100%;
}

.activityStatusSectionMobile {
    color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}

.activityDetailsSection {
    height: calc(7rem - 2.75rem);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    gap: 1.25rem;
}

.userSection {
    width: 100%;
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
}

@media screen and (min-width:320px) and (max-width:1024px) {
    .body {
        padding-top: 1.25rem;
        flex-direction: column;
        gap: 1.5rem;
        height: auto;
    }

    .nftSection {
        width: 100%;
        height: 100%;
    }

    .frame {
        padding: 2.125rem 0.5rem 2.375rem 0.5rem;
        width: calc(100% - 1rem);
        height: calc(100% - 2.125rem - 2.375rem);
        gap: 3rem;
    }

    .title1 {
        color: #FFF;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 151.005%;
    }

    .title2 {
        color: rgba(255, 255, 255, 0.79);
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
    }

    .nftImageSection {
        width: 14.25rem;
        height: calc(19.25rem - 00.875rem);
    }

    .activitySection {
        width: calc(100% - 3rem);
        height: auto;
        margin-left: 0;
    }

    .activityTable {
        display: none;
    }

    .activityTableForMobileScreen {
        display: contents;
    }

    .arrowForward:hover {
        cursor: pointer;
    }
}