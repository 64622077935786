.fullScreenModalContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.blur {
    backdrop-filter: blur(8px);
}

.modal {
    width: 378px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* Stay in place */
    z-index: 1;
    flex-shrink: 0;
    border: 1px solid rgba(214, 217, 255, 0.21);
    border-radius: 20px;
    background: rgba(1, 2, 22, 0.95);
}

.modalContent {
    display: flex;
    width: 330px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 24px 24px 20px 24px;
    animation-name: animatetop;
    animation-duration: 0.4s
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.headerSection {
    display: inline-block;
    width: 100%;
}

.modalTitle {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 151.005%;
    float: left;
}

.closeButton {
    width: 24px;
    height: 24px;
    float: right;
}

.closeButton:hover {
    cursor: pointer;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.625rem;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    width: 100%;
}

.nftSection {
    display: flex;
    width: calc(100% - 1.5rem);
    height: 3.75rem;
    padding: 0.625rem 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    flex-shrink: 0;
    border-radius: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.40);
    border-bottom: 1px solid rgba(255, 255, 255, 0.40);
    background: linear-gradient(161deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 101.7%);
    backdrop-filter: blur(8.831887245178223px);
}

.nftContent {
    display: flex;
    width: 210px;
    align-items: center;
    gap: 16px;
}

.nftImage {
    width: 61px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 14px;
    border-bottom: 1.232px solid #000C8C;
    background: url('../../assets/images//nft.png') lightgray 50% / cover no-repeat;
    backdrop-filter: blur(5.1352949142456055px);
}

.nftTitleAndBadge {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
}

.nftTitle {
    width: 118px;
    height: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 2.776px;
    color: #FFF;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;

}

.nftBadgeSection {
    display: flex;
    height: 14px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: #990872;
}

.nftBadgeContent {
    display: flex;
    align-items: center;
    gap: 2px;
}

.badgeIcon {
    width: 14px;
    height: 14px;
}

.nftBadgeText {
    display: flex;
    width: 75px;
    height: 17px;
    flex-direction: column;
    justify-content: flex-end;
    color: #FFF;
    font-family: Oxanium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.toText {
    color: rgba(255, 255, 255, 0.50);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
}

.emailAddressSection {
    display: flex;
    width: calc(100% - 2rem);
    padding: 0.75rem 1rem;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #2E2F47;
    flex: 1 0 0;
    color: rgba(250, 250, 250, 0.50);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    background-color: #010216F2;
}

.submitSection {
    display: flex;
    width: 100%;
    height: 47px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #2537FE;
    border: none;
}

.submitSection:hover:not(:disabled) {
    cursor: pointer;
    background-color: #0515c4;
}

.submitSection:disabled {
    cursor: not-allowed;
}

.transferButton {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.footer {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    width: inherit;
    justify-content: center;
}

.footerText {
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.infoTextSection {
    height: 16rem;
    width: 20.5rem;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.txUrl {
    color: #FFF;
}

.error {
    color: #e02424;
    font-size: 14px;
}

@media screen and (min-width:320px) and (max-width:768px) {
    .modal {
        width: 94%
    }

    .modalContent {
        gap: 2rem;
        padding: 1.5rem 0.75rem 1.25rem 0.75rem;
        width: calc(100% - 1.5rem)
    }
}