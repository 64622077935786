html,
body,
#app,
#app>div {
  height: 100%;
  width: 100%
}

.App {
  background-image: url("./assets/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  padding: 3rem 3.75rem 6.625rem 3.75rem;
  width: calc(100% - 7.5rem);
  height: calc(100% - 3rem - 6.625rem);
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
}

@media screen and (min-width:320px) and (max-width:768px) {
  .App {
    padding: 2.5rem 0.75rem 7.5rem 0.75rem;
    width: calc(100% - 1.5rem);
    height: calc(100% - 2.5rem - 7.5rem);
  }
}