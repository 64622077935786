.fullScreenModalContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.blur {
    backdrop-filter: blur(8px);
}

.modal {
    width: 378px;
    height: 372px;
    position: absolute;
    left: 50%;
    top: calc(50% + 15px);
    transform: translate(-50%, -50%);
    /* Stay in place */
    z-index: 1;
    flex-shrink: 0;
    border: 1px solid rgba(214, 217, 255, 0.21);
    border-radius: 20px;
    background: rgba(1, 2, 22, 0.95);
}

.modalContent {
    display: flex;
    width: 330px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 25px 24px 20px 24px;
    animation-name: animatetop;
    animation-duration: 0.4s
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.header {
    display: inline-block;
    width: 100%;
}

.modalTitleSection {
    display: flex;
    align-items: center;
    gap: 8px;
    float: left;
}

.companyLogo {
    width: 25.983px;
    height: 29px;
}

.companyName {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.closeButton {
    width: 24px;
    height: 24px;
    float: right;
}

.closeButton:hover {
    cursor: pointer;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.25rem;
    width: 100%;
}

.signInSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.125rem;
    width: 100%;
}

.signInOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
}

.ssoOptions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.875rem;
    width: 100%;
}

.logoSection {
    display: flex;
    width: 33%;
    height: 45px;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 8px;
    border: 1px solid #2E2F47;
    background-color: #010216F2;
}

.logoSection:hover{
    cursor: pointer;
}

.googleLogo {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1px;
    justify-content: center;
    align-items: center;
}

.orSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.orText {
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.emailSection {
    display: flex;
    width: calc(100% - 1rem);
    padding: 0.75rem 0 0.75rem 1rem;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #2E2F47;
    color: rgba(250, 250, 250, 0.50);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    background-color: #010216F2;
}

.emailSection:hover {
    cursor: not-allowed;
}

.emailText {
    flex: 1 0 0;
    color: rgba(250, 250, 250, 0.50);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.submitSection {
    display: flex;
    width: 100%;
    height: 47px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #2537FE;
    border: none;
}

.submitSection:hover {
    background-color: #0515c4;
    cursor: pointer;
  }

.continueButton {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.footer {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.footerText {
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

@media screen and (min-width:320px) and (max-width:768px) {
    .modal {
        width: 94%
    }

    .modalContent {
        gap: 2rem;
        padding: 1.5rem 0.75rem 1.25rem 0.75rem;
        width: calc(100% - 1.5rem)
    }

    .ssoOptions {
        gap: 0.313rem
    }
}