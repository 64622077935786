.fullScreenModalContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.blur {
    backdrop-filter: blur(8px);
}

.modal {
    width: 378px;
    flex-shrink: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* Stay in place */
    z-index: 1;
    border: 1px solid rgba(214, 217, 255, 0.21);
    border-radius: 20px;
    background: rgba(1, 2, 22, 0.95);
}

.modalContent {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 38px;
    padding: 24px 24px 20px 24px;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: calc(100% - 48px);

}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
}

.userProfileContainer {
    display: inline-flex;
    align-items: flex-start;
    gap: 2.875rem;
}

.closeIcon {
    height: 24px;
    width: 24px;
}

.closeIcon:hover {
    cursor: pointer;
}

.userProfileSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-left: 51px;
}

.userProfile {
    display: flex;
    align-items: center;
    gap: 14px;
}

.userProfileImage {
    width: 72px;
    height: 72px;
    border-radius: 54px;
}

.userDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
}

.walletAddressSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.walletConnectionSection {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 8px 0 8px;
    justify-content: center;
}

.userName {
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: capitalize;
}

.walletAddressAndCopyButton {
    display: flex;
    align-items: center;
    gap: 4px;
}

.walletAddress {
    color: rgba(255, 255, 255, 0.50);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.copyButton {
    width: 14px;
    height: 14px;
    opacity: 0.5;
}

.copyButton:hover {
    cursor: pointer;
}

.walletBalance {
    display: flex;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #FFF;
}

.walletConnectionStatus {
    width: 10px;
    height: 10px;
    border-radius: 14px;
    background: var(--Green-2, #27AE60);
}

.walletConnectionText {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
}

.walletOperationsSection {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    width: 100%;
}

.actionButton {
    display: flex;
    width: 33%;
    height: 69px;
    padding: 10px 0 10px 0;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background-color: #010216F2;
    justify-content: center;
}

.disabledActionButton:hover {
    cursor: not-allowed !important;
}

.actionButton:hover {
    cursor: pointer;
    filter: brightness(80%);
}

.actionIconAndText {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.actionIcon {
    width: 24px;
    height: 24px;
    opacity: 0.6;
}

.actionText {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.disconnectButtonSection {
    display: flex;
    width: 100%;
    height: 42px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #010216F2;
    border-radius: 8px;
    border: 1px solid #F06464;
}

.disconnectButtonSection:hover {
    cursor: pointer;
    filter: brightness(80%);
}

.disconnectButtonBody {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.logoutIcon {
    width: 20px;
    height: 20px;
}

.logoutText {
    color: #FF8383;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

@media screen and (min-width:320px) and (max-width:768px) {
    .modal {
        width: 94%
    }

    .modalContent {
        gap: 2rem;
        padding: 1.5rem 0.75rem 1.25rem 0.75rem;
        width: calc(100% - 1.5rem)
    }
}